<template>
    <div>
        修改密码
        <input type="text">
    </div>
</template>

<script>
export default {
    name: 'password',
    data() {
        return {

        }
    },
}
</script>

<style scoped>

</style>
